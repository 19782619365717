<template>
	<div style="">
		<h1>Dashboard</h1>
	</div>
</template>
<script>
export default {
  name:'TaquillaDashboard',
  created () {
    this.$store.commit('SET_LAYOUT', 'Taquilla')
  }
}
</script>